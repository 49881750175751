import React, { Component } from 'react';
import $ from 'jquery';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { ExcelExport, ExcelExportColumn } from '@progress/kendo-react-excel-export';
import { process } from '@progress/kendo-data-query';
import Snackbar from '@material-ui/core/Snackbar';
import '../reports/reports.scss'
import Table from 'react-bootstrap/Table';
import ApiUrlCall from '../API/apiurl';
import html2canvas from "html2canvas";
import CommonFunction from '../common_function'
import Calendar from 'react-calendar/dist/entry.nostyle'
import { thisExpression } from '@babel/types';
import analytics from '../../Config/firebase'
import Amplify, { Analytics } from 'aws-amplify';
import awswebapp from '../../aws-exports';
import constant from '../constant.json';
import { MDBDataTable } from 'mdbreact';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'mdbreact/dist/css/mdb.css';
import { withTranslation } from 'react-i18next';
import Timekeeper from 'react-timekeeper';
import common_function from '../common_function';
import { Route, Redirect, Switch, Link, BrowserRouter as Router } from 'react-router-dom'
import { el } from 'date-fns/locale';

const jsPDF = require('jspdf')


var Chart = require("chart.js");
const initialState = {
  dateRange: {}
};

const data1 = [

   {
    label: 'Partner Name',
    field: 'partner_name',
    sort: 'asc',
    width: 150,
  },
    
  {
    label: 'Total Vehicles',
    field: 'vehicle_count',
    sort: 'asc',
    width: 150,
  },
  {
    label: 'O KM',
    field: 'zero_km',
    sort: 'asc',
    width: 150,
  },
  {
    label: '0 - 50 KM',
    field: 'zero_to_fifty_km',
    sort: 'asc',
    width: 150,
  },
  {
    label: '50 - 100 KM',
    field: 'fifty_to_hundred_km',
    sort: 'asc',
    width: 150,
  },
  {
    label: '>100 Km',
    field: 'greater_than_hundred_km',
    sort: 'asc',
    width: 150,
  },

  {
    label: 'Connection Lost',
    field: 'connection_lost',
    sort: 'asc',
    width: 150,
  },

  
]

var GRAPH_BAR_BACKGROUND_COLORS = [
  'rgba(0, 41, 183)',
  'rgba(255, 200, 23)',
  'rgba(244, 129, 31)',
  'rgba(146, 182, 56)',
  'rgb(255, 204, 179)',
  'rgb(255, 126, 0)',
  'rgb(255, 204, 179)',
  'rgb(179, 242, 255)',
  'rgb(0, 77, 153)',
  'rgb(255, 230, 179)'
]
class Summary_Report_indusMP extends Component {

  _exporter;
  _exporter_ignition;
  _exporter_distance;
  _exporter_duration;
  _exporter_idling;
  _exporter_expense;
  _exporter_trip;
  _exporter_alert;
  _exporter1_table;

  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
      counter: 0,
      selectedDays: [],
      dateRange: initialState,
      isRange: this.props.range,
      startDate: new Date(),
      endDate: new Date(),
      serial_number_is: [],
      trips_report: '',
      hideGraph: false,
      showgraph: false,
      checked: false,
      uncheck: true,
      openAlert: false,
      vehicle_name: '',
      message_vehicle: '',
      chckebox_value: [],
      excel_table_data: '',
      export_table_data: '',
      label_list: '',
      vehicleName: '',
      reportDate: "12/01/2020-20/01/2020",
      dateRange: initialState,
      is_checked: true,
      serial_number: '',
      checked_value: [],
      vehicle_state: '',
      vehicle_list: [],
      chartData: '',
      chart_array: [],
      chart_array1: [],
      chart_array2: [],
      chart_array3: [],
      chart_array4: [],
      chart_array5: [],
      chart_array6: [],
      chart_array7: [],
      sevenDayFlag: true,
      ignData: '',
      idlingData: '',
      disData: '',
      durData: '',
      tripData: '',
      alertData: '',
      expenseData: '',
      startTime: "12:00 am",
      sendStartTime: "00:00",
      showStartTime: false,
      endTime: "11:59 pm",
      sendEndTime: "23:59",
      showEndTime: false,
      showTable: true,
      datatable: [],
      checkBoxList: [],
      generateReport : false,

      groupDataList: [],
      vlcGroupDataList: [],
      initial_stage : true,
      counter: 0,
      checkbox_value_forGroup: [],
      option_type : ["Vehicle Based Report","Group Based Report"],
      choice_value : "Vehicle Based Report",

      redirectToReferrer: false,
    }
    // this.checkbox = this.checkbox.bind(this);

  }

  export_vehicle_report_table = () => {
    
    this.save( this._exporter1_table );
  //   this._exporter1_table.save();
   }
  save = (component) => {
    
    const options = component.workbookOptions();
    const rows = options.sheets[0].rows;

    let altIdx = 0;
    rows.forEach((row) => {
        if (row.type === 'data') {
            
                row.cells.forEach((cell) => {
                    
                    if(cell.value.props != undefined){
                        var link = cell.value.props.href
                        
                        cell.formula = '=HYPERLINK("'+link+'","Click for Google Maps")'
                    }
                    
                });
            
            
        }
    });

    component.save(options);
};

  // show calendar click event start
  showCalendar = (e) => {
    var calendardiv = e.currentTarget.parentNode.parentNode.nextSibling
    $(calendardiv).toggle();
  }


  // drop down show option list function start
  // dropDownClick = (e) => {
  //   $('#vehicles_select').slideDown('fast');
  //   var dropdownwidth = e.currentTarget.offsetWidth
  //   var checklist = e.currentTarget.nextSibling
  //   $(checklist).css({
  //     width: dropdownwidth
  //   })
  //   $(checklist).slideToggle(300);

  // }
  // drop down show option list function end


 
  componentDidMount() {
    analytics.logEvent('screen_view', {
      screen_name: "TripReport"
    })
    analytics.logEvent('page_view', {
      page_title: "TripReport",
    })
    Amplify.configure(awswebapp);
    Analytics.record({
      name: constant.awsName,
      // Attribute values must be strings
      attributes: { screen_name: 'WEB_TRIP_REPORT' }
    });

    this.getVehicleList();
    this.getVehicleListForGroup();
  }


  getVehicleList = () => {
    var data = {}
    $('#processing').show()
    ApiUrlCall.getVehcile_Name_latest(data).then(response => {

      if (response.data.response_code === 200 && response.data.is_success === true) {
        this.setState({
          vehicle_name: response.data.data,
          serial_number_is: response.data.data.serial_number
  
        }, () => {
          const serial_number = []
          for (var i = 0; i < this.state.vehicle_name.vehicle_list.length; i++){
            if (this.state.vehicle_name.vehicle_list[i].is_expired === false) {
               serial_number.push(this.state.vehicle_name.vehicle_list[i].serial_number);
               break;
            }
          }

          if (serial_number.length <= 1) {
             this.setState({
               chckebox_value : serial_number
             })
            this.generate_Default_Report_vehicle(serial_number);


          }
        })

      }
    }).catch(error => {
      console.log(error, "error while fetching vehicle list");
    })
  }
  

  
 showStartDate = (e) => {
  $(e.currentTarget).next().slideToggle(200, "swing");
  this.setState({ showCalendar: true });
}
  // this method is used to formate date as per UI to show.
  dateFormate = (rdate) => {
    var date = new Date(rdate);
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    if (day < 10) {
      day = '0' + day;
    }
    if (month < 10) {
      month = '0' + month;
    }
    var convertDate = day + '/' + month + '/' + year
    return convertDate
  }

  showTimePicker = (e) => {
    $(e.currentTarget).next().slideToggle()
  }

  selectVehicleOpt = (list) => {
    var input = $('#vehicles_select').find('input')
    for (let j = 0; j < input.length; j++) {
      const element = input[j];
      //console.log('value of input is', $(element).val())
      for (let i = 0; i < list.length; i++) {
        const element1 = list[i];
        if ($(element).val() === element1) {
           //console.log('selected list is', $(element))
          $(element).prop('checked', true)
        } else {
          // console.log('unselected list is', $(element))
        }
      }
    }
  }
  
    

  createTableReport = () => {
    const { trips_report } = this.state
    var array = []
    console.log('trips_report ', trips_report)
    var table_start_date = CommonFunction.createServerDateTime(this.state.startDate, this.state.sendStartTime)
    var table_end_date = CommonFunction.createServerDateTime(this.state.endDate, this.state.sendEndTime)
    if(trips_report.trips_report_list.length>0){
    trips_report.trips_report_list[0].total_distance=trips_report.trips_report_list[0].distance;
    for (let i = 1; i < trips_report.trips_report_list.length; i++)
    {
    var element = trips_report.trips_report_list[i].distance;
    //console.log(trips_report.trips_report_list[i].total_distance,"alfa")
    var total_distance = trips_report.trips_report_list[i-1].total_distance + element
    trips_report.trips_report_list[i].total_distance = total_distance
    //console.log(trips_report.trips_report_list[0].name,"---------------->")
    const per_name = trips_report.trips_report_list[i-1];
    const next_name = trips_report.trips_report_list[i];

    if (next_name.name != per_name.name){
       total_distance=trips_report.trips_report_list[i].distance;
      }

    console.log("prachi 4");  
    trips_report.trips_report_list[i].total_distance=total_distance;
    //console.log(trips_report)
  }
}



    for (let i = 0; i < trips_report.trips_report_list.length; i++) {
      const element = trips_report.trips_report_list[i];
      var start_address =  element.start_address
      var end_address = element.end_address
      if(start_address==""){
        start_address = <a href={'https://www.google.com/maps?q='+element.start_lat+','+element.start_lng } target="_blank">Click for Google Maps</a>
      }
      if(end_address==""){
        end_address = <a href={'https://www.google.com/maps?q='+element.end_lat+','+element.end_lng} target="_blank">Click for Google Maps</a>
      }
      

      var data = {
        partner_name: element.partner_name,
        vehicle_count: element.vehicle_count,
        zero_km: element.zero_km || 0, // Ensure defaults for empty or missing values
        zero_to_fifty_km: element.zero_to_fifty_km || 0,
        fifty_to_hundred_km: element.fifty_to_hundred_km || 0,
        greater_than_hundred_km: element.greater_than_hundred_km || 0,
        connection_lost: element.connection_lost || 0,
        
    };

      array.push(data)
    }
    this.setState({
      datatable: array
    })
  }

  generate_Default_Report_vehicle = (serial_number) => {
    const {startDate, endDate, generateReport} = this.state
    $("#processing").show()
    if (this.state.choice_value == "Vehicle Based Report"){
      this.selectVehicleOpt(serial_number)
    }
    this._isMounted = true;
    if(!generateReport){
      var past_date = new Date();
      const { t } = this.props
      past_date.setDate(past_date.getDate() - 0);
      this.state.startDate = past_date
      past_date.setHours(0, 0, 0, 0)
      var current_date = new Date();
      current_date.setDate(current_date.getDate());
      this.state.endDate = current_date
      this.forceUpdate()
    }
    past_date = CommonFunction.createServerDateTime(startDate, this.state.sendStartTime)
    current_date = CommonFunction.createServerDateTime(endDate, this.state.sendEndTime)
 
    if (this.state.choice_value == "Select Choice"){
      $('#processing').hide()     
      this.setState({   
        openAlert : true,
        message_vehicle : "Please Select Choice"     
      })     
      return
    }
    //console.log(serial_number.length,"ooooooooooooooo")
    if (serial_number.length > 10){
      $('#processing').hide()     
      this.setState({   
        redirectToReferrer : true,    
      })     
      return
    }
    if (serial_number.length == 0){
      $('#processing').hide()     
      this.setState({   
        openAlert : true,
        message_vehicle : "Please Select Vehicle"     
      })     
      return
    }
    
    const Summery_report_details = {
      serial_number_list: serial_number,
      start_time: past_date,
      end_time: current_date
    };

    JSON.stringify(Summery_report_details)





    
    ApiUrlCall.summary_report_mp_indus(Summery_report_details).then(res => {
      if (res.data.is_success === true) {
        $("#processing").hide()

        this.setState({
          trips_report: res.data.data
        }, () => {
          this.createTableReport()
        }
        )
      }
      else {
    
        this.setState({ openAlert : true, 
          message_vehicle : "Something went wrong"
         })
        $('#processing').hide()

      }

    }).catch(err => {
      $("#processing").hide()
      console.log("journy report error ", err)
      this.setState({ showgraph: true })

      // $('#processing').hide()
    })


  }
  handleChecks() {
    this.setState({ isChecked: !this.state.isChecked });
  }

  hideAllDropDown = () => {
    $('#vehicles_select').parent().hide()
    $('#123').parent().hide()
    $('#456').parent().hide()
    $('#end_date_cal').hide()
    $('#start_date_cal').hide()
    this.setState({
      showStartTime: false,
      showEndTime: false,
    })
  }

  create_Report = () => {
    const {startDate , endDate} = this.state
    var Difference_In_Time = endDate.getTime() - startDate.getTime();
    var Difference_In_Days = Math.round(Difference_In_Time / (1000 * 3600 * 24));
    if(Difference_In_Days > 90){ 
      $('#processing').hide()     
      this.setState({
        sevenDayFlag : false,   
        openAlert : true,
        message_vehicle : "Not allowed to select more than 90 days"     
      })     
      return 
    }else{
      this.setState({
        sevenDayFlag : true
      })
    } 
    this.hideAllDropDown()    
    this.setState({
      generateReport : true
    },() => {
      var chk_list= []
      if (this.state.choice_value == "Vehicle Based Report"){
        for (let i = 0; i < this.state.chckebox_value.length; i++){
          var element = this.state.chckebox_value[i];
           {
              const element_new = element.toString()
              chk_list.push(element_new)
              
          }
        }
      }
      if (this.state.choice_value == "Group Based Report"){
        var serialNoList1 = this.state.checkbox_value_forGroup
        let uniqueChars = [...new Set(serialNoList1)]
        chk_list = uniqueChars
      }
      this.generate_Default_Report_vehicle(chk_list)    
    })
  }

  reset = () => {
    this.setState({ dateRange: initialState });

  }
  onChange(e) {
    // current array of options
    const options = this.state.chckebox_value
    let index
    let counter = 0
    // check if the check box is checked or unchecked
    //console.log(counter,"rrrrrrrrrrrrrrrr")
    if (counter >= 10) {
      this.state.is_checked = false
    }

    counter = counter + 1
    if (e.target.checked) {
      //console.log(e.target.checked)
      options.push(+e.target.value)
      this.forceUpdate()
      // $('#vehicles_select').slideUp(200)
      ///add the numerical value of the checkbox to options array

    } else {
      // or remove the value from the unchecked checkbox from the array
      index = options.indexOf(+e.target.value)
      

      options.splice(index, 1)
      this.forceUpdate()
    }

    // sort the array
    options.sort()
    CommonFunction.manage_vehicle_new(this.state.is_checked)
    

    // update the state with the new array of options
    this.setState({ options: options })
  }

  StartdateClicked = date => {
    this.setState({
      startDate: date,
      isStartDateSelected: true,
      period_type: 'CUSTOM',
      showCalendar: false
    });
  }
  EnddateClicked = date => {
    this.setState({
      endDate: date,
      isEndDateSelected: true,
      period_type: 'CUSTOM',
      showCalendar: false
    });
  }
  showEndDate = (e) => {
    $(e.currentTarget).next().slideToggle(200, "swing");
    this.setState({ showCalendar: true });
  }
  doubleDigit = (value) => {
    if (value <= 9) {
      return '0' + value
    } else {
      return value
    }
  }
  selectStartTime = (newTime) => {
    this.setState({
      startTime: newTime.formatted12,
      sendStartTime: newTime.formatted24
    });
  }

  showStartTimeContent = () => {
    $('#end_date_cal').slideUp()
    $('#start_date_cal').slideUp()
    if (this.state.showStartTime) {
      this.setState({
        showStartTime: false,
        showEndTime: false
      });
    } else {
      this.setState({
        showStartTime: true,
        showEndTime: false
      });
    }
  }

  selectEndTime = (newTime) => {
    this.setState({
      endTime: newTime.formatted12,
      sendEndTime: newTime.formatted24
    });
  }

  showEndTimeContent = () => {
    $('#end_date_cal').slideUp()
    $('#start_date_cal').slideUp()
    if (this.state.showEndTime) {
      this.setState({
        showEndTime: false,
        showStartTime: false
      });
    } else {
      this.setState({
        showEndTime: true,
        showStartTime: false
      });
    }
  }

  //////////////////Vehicle Group List//////////////////

  getVehicleListForGroup = () => {
    var data = {}
    ApiUrlCall.ShowVehicleGroupList(JSON.stringify(data)).then(response => {
      if (response.data.response_code == 200 && response.data.is_success) {
        this.setState({
          groupDataList: response.data.data.group_data_list,
        })
        var data1 = []
        for (var i = 0; i < this.state.groupDataList.length; i++) {
          var obj = { groupName: this.state.groupDataList[i].group_name,serialNoList:[] }
          var vehicle_data = this.state.groupDataList[i].vehicle_data_list
          
          for (var j = 0; j < vehicle_data.length; j++) {
            obj.serialNoList.push(vehicle_data[j].serial_number)
          }          
          data1.push(obj)
        }
        this.setState({ vlcGroupDataList: data1, })
      }
    }).catch(error => {
      console.log('error while fetching vehicle group data', error)
    })
  }
  
  
  onChangeForGroup(e) {
    // current array of options
    const options = this.state.checkbox_value_forGroup
    let index
    let {counter} = this.state
    //$('#all_vehicle').prop('checked',false)    
    // let counter = 0;
    // if (counter >= 4) {
    //   this.state.is_checked = false
    // }
  
    //counter = counter + 1
    // check if the check box is checked or unchecked
    if (e.target.checked) {
      // add the numerical value of the checkbox to options array 
      const list = e.target.value.split(",")     
      //options.push(...list)
      //console.log(e.target.value)
      //this.forceUpdate()
      this.state.checkbox_value_forGroup.push(...list)
      
      //console.log(this.state.counter,'vehicle array list1234', this.state.checkbox_value_forGroup)
  
      // $('#vehicles_select').slideUp(200)
  
    } else {
      // or remove the value from the unchecked checkbox from the array      
      //index = options.indexOf(+e.target.value)
      const list = e.target.value.split(",")      
      //options.splice(index, 1)
      //this.forceUpdate()
      list.map(e =>{
        index = this.state.checkbox_value_forGroup.indexOf(e)
        this.state.checkbox_value_forGroup.splice(index,1) 
      })
  
      //console.log(this.state.counter,'vehicle array list in elseeeeeeeeeeee', this.state.checkbox_value_forGroup)
    }
  
    // sort the array
    options.sort()
  
    // update the state with the new array of options
    // this.setState({ options: options })
  }
  
  choiceChange(e){
    //console.log(e.target.value)
    if (e.target.checked){
      if (e.target.value == "Vehicle Based Report"){
        $('#option2').prop('checked', false)
        //console.log("DDDDDDDDDDDD")
      }
      if (e.target.value == "Group Based Report"){
        $('#option1').prop('checked', false)
        //console.log("LLLLLLLLLLLLLl")
      }
       //console.log(e.target.value)
       this.setState({
         choice_value : e.target.value
       })
    }
    else{
      this.setState({
        choice_value : "Select Choice"
      })
    }
  }
  
  searchVehicle= (e) => {
    let input = document.getElementById('searchbar').value
    input=input.toLowerCase();
    let x = document.getElementsByClassName('vehicle');

    for (var i = 0; i < x.length; i++){
      if (!x[i].innerHTML.toLowerCase().includes(input)) {
          x[i].style.display="none";
      }
      else {
          x[i].style.display="";               
      }
    }
  }
  
  /////////////////////////////////////////////////////


  render() {
    const {redirectToReferrer} = this.state
    if (redirectToReferrer === true) {
      return <Redirect to= {{pathname:"/sheduler_report",state: { id:22 }}} />
    }

    const { t } = this.props
    const { checkBoxList } = this.state
    // console.log(this.state.vehicle_report, "this is state data")
    var minDate = new Date(); minDate.setDate(minDate.getDate() - 90);
        
    if (this.state.vehicle_name) {
      let counter = 0
      var vehicledataname = this.state.vehicle_name.vehicle_list.map(vehicledataname => {
        this.state.serial_number_is = vehicledataname.serial_number
       
        if (counter >= 10) {
          this.state.is_checked = false
        }

        counter = counter + 1
        if(vehicledataname.is_expired == false){
          return (
            <div class="vehicle">
                <Grid container className="select_opt_row" >
                  <Grid item xs={2}>
                    <input type="checkbox"
                      // onClick={this.checkbox}
                      //defaultChecked={CommonFunction.manage_vehicle_new(this.state.is_checked)}
                      value={vehicledataname.serial_number}
                      onChange={this.onChange.bind(this)} name="vehicle_tick" />
                  </Grid>
                  <Grid item xs={10}>
                    <p className="p_margin0">{vehicledataname.name}</p>
                  </Grid>
                </Grid>
            </div>
          )
        }
        

      })
    }

    if (this.state.vlcGroupDataList) {
      let counter = 0
      //console.log(this.state.vehicle_name,)
      var groupdataname = this.state.vlcGroupDataList.map(groupdataname => {
        //console.log(groupdataname.serialNoList)
        //this.state.serial_number_is = vehicledataname.serial_number
        return (
          <Grid container className="select_opt_row">
            <Grid item xs={2}>
              <input type="checkbox"
                // onClick={this.checkbox}
                // defaultChecked={this.state.checkbox_value}
                value={groupdataname.serialNoList}
                onChange={this.onChangeForGroup.bind(this)} name="vehicle_tick" />
            </Grid>
            <Grid item xs={10}>
              <p className="p_margin0">{groupdataname.groupName}</p>
            </Grid>
          </Grid>
        )
      })
    }

    // if (this.state.vehicle_report) {
     
    //   if (this.state.ignData) {
    //     var iginition_data = process(this.state.ignData, {

    //     }).data;
    //   }
    //   if (this.state.idlingData) {
    //     var idling_data = process(this.state.idlingData, {

    //     }).data;
    //   }
    //   if (this.state.durData) {
    //     var duration_data = process(this.state.durData, {

    //     }).data;
    //   }
    //   if (this.state.disData) {
    //     var distance_data = process(this.state.disData, {

    //     }).data;
    //   }
    //   if (this.state.alertData) {
    //     var alert_data = process(this.state.alertData, {

    //     }).data;
    //   }
    //   if (this.state.tripData) {
    //     var trip_data = process(this.state.tripData, {

    //     }).data;
    //   }
    //   if (this.state.expenseData) {
    //     var expense_data = process(this.state.expenseData, {

    //     }).data;
    //   }

    // }

    if (this.state.chckebox_value.length > 0) {
      if (this.state.chckebox_value.length == 1) {
        var lableText = this.state.chckebox_value.length + " " + t('ars.sum.v1')
      } else {
        var lableText = this.state.chckebox_value.length + " " + t('ars.sum.v2')
      }
    } else {
      var lableText = t('ct.select.vehicle')
    }
    var excel_name = "Summary_report_indus_MP" + CommonFunction.downloadReportDate(this.state.date) + ".xlsx"
    return (
      <div>
        <div className="report_filter_div">
          <Grid spacing={2} container>
            <Grid item xs>
              {/* <CSVLink data={this.state.vehicle_report}>
             Download me
            </CSVLink>;   */}
              <p>{t('ct.startdate')}</p>
              <div className="input_div" onClick={this.showStartDate}>
                <input type="text" placeholder={t('ct.startdate')} id="start_date" disabled value={this.dateFormate(this.state.startDate)} className="journey_input"></input>
                <img src="images/fill-111.png" className="vd_journey_icon"></img>
              </div>
              {this.state.showCalendar && (
              <div className="calendar_parent" id="start_date_cal">
                <Calendar
                  selectRange={false}
                  onChange={this.StartdateClicked}
                  value={this.state.startDate}
                  maxDate={this.state.date}
                  minDate={minDate}
                />
              </div>
               )}
            </Grid>
            <Grid item xs>
              <p>Start Time</p>
              <div onClick={this.showStartTimeContent} className="time_div">
                <p>{this.state.startTime}</p>
              </div>
              {this.state.showStartTime &&
                <div style={{ position: "absolute", zIndex: "9" }}>
                  <Timekeeper
                    time={this.state.startTime}
                    switchToMinuteOnHourSelect
                    onDoneClick={() => {
                      this.setState({
                        showStartTime: false
                      });
                    }}
                    onChange={this.selectStartTime}
                  />
                </div>
              }
            </Grid>
            <Grid item xs>
              <p>{t('ct.enddate')}</p>
              <div className="input_div" onClick={this.showEndDate}>
                <input type="text" placeholder={t('ct.enddate')} id="end_date" disabled value={this.dateFormate(this.state.endDate)} className="journey_input"></input>
                <img src="images/fill-111.png" className="vd_journey_icon"></img>
              </div>
              {this.state.showCalendar && (
              <div className="calendar_parent" id="end_date_cal">
                <Calendar
                  selectRange={false}
                  onChange={this.EnddateClicked}
                  value={this.state.endDate}
                  maxDate={this.state.date}
                  minDate={minDate}
                />
              </div>
                )}
            </Grid>
            <Grid item xs>
              <p>End Time</p>
              <div onClick={this.showEndTimeContent} className="time_div">
                <p>{this.state.endTime}</p>
              </div>
              {this.state.showEndTime &&
                <div style={{ position: "absolute", zIndex: "9" }}>
                  <Timekeeper
                    time={this.state.endTime}
                    switchToMinuteOnHourSelect
                    onDoneClick={() => {
                      this.setState({
                        showEndTime: false
                      })
                    }}
                    onChange={this.selectEndTime}
                  />
                </div>
              }
            </Grid>


            {/* Group List Start */}
            <Grid item xs style={{ display: (this.state.choice_value === "Group Based Report") ? "" : "none" }}>
                  <label className="work_sans_regular16">Groups</label>
                  <div style={{ position: "relative" }} onClick={this.dropDownClick}>
                    <div className="summary_report_filters">
                      <label>All Groups</label>
                    </div>
                    <i class="fa fa-angle-down search_icon" aria-hidden="true" style={{ color: "#F0762B" }}></i>
                  </div>
                  <div className="all_region_dd">
                    <div className="dropdown_parent_other" id="vehicles_select" id = "456">
                      {groupdataname}                  
                    </div>
                  </div>
                </Grid>

                {/* Group List End */}

            <Grid item xs>
              <label>&nbsp;</label>
              <button className="btn orange-btn btn-block" style={{ marginTop: "6px" }} onClick={this.create_Report}>{t('ars.generate')}</ button>
            </Grid>
            <Grid container item xs className="pdf_excel_download" style={{display : this.state.showTable ? "" : "none"}}>
              {/* <img src="images/group-6-copy-2.png" className="vd_journey_icon"></img> */}
              <label>&nbsp;</label>
              <Grid item xs={12}><label>&nbsp;</label></Grid>
              <Grid item xs={6} onClick={this.export_vehicle_report_table}>
                <img src="images/group-6-copy-2.png" style={{ marginRight: "5px" }}></img>
                <label className="BSC_semi_bold18">{t('ars.excel')}</label>
                <ExcelExport
                  data={this.state.datatable}
                  fileName={excel_name}
                  ref={(exporter) => { this._exporter1_table = exporter; }}>
                  <ExcelExportColumn field="partner_name" title="partner_name" />
                  <ExcelExportColumn field="vehicle_count" title="vehicle_count" />
                  <ExcelExportColumn field="zero_km" title="zero_km" />
                  <ExcelExportColumn field="zero_to_fifty_km" title="zero_to_fifty_km" />
                  <ExcelExportColumn field="fifty_to_hundred_km" title="fifty_to_hundred_km" />
                  <ExcelExportColumn field="greater_than_hundred_km" title="greater_than_hundred_km" />
                  <ExcelExportColumn field="connection_lost" title="connection_lost" />
                </ExcelExport>
              </Grid>
            
            </Grid>
          </Grid>
         
        </div>

        <div style={{ marginTop: "25px" }}>
          <div className="graph_view_btn">
            <div className={this.state.showTable ? "active_view_btn" : "inactive_view_btn"} style={{ display: "inline-block" }} onClick={this.tableViewClick} id="table_btn">
              <label>table</label>
            </div>
          </div>
        </div>

        <div className="vr_table_view" style={{ display: this.state.showTable ? "" : "none", marginBottom: "25px",marginTop: "50px" }}>
        
          <p className="p_header" style={{position : 'absolute', lineHeight : "2em"}}>Total Number Of Entries : {CommonFunction.zeroAppend(this.state.datatable.length)}</p>
          <MDBDataTable
            displayEntries={false}
            // searching={false}
            maxHeight="700px"
            paging={false}
            noBottomColumns = {true}
            //scrollX
            entries={10}
            striped
            bordered
            data={{ columns: data1, rows: this.state.datatable }}
          />
        </div>

        <div style={{ marginTop: "30px", display: !this.state.showTable ? "" : "none" }}>
        </div>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={1800}
          onClose={(e, reason) => {
            if (reason === 'clickaway') {
              return;
            }
            this.setState({ openAlert: false })
          }
          }
          open={this.state.openAlert}
          message={this.state.message_vehicle}
        />
      </div>
    );
  }

}


export default withTranslation()(Summary_Report_indusMP);
