import axios from 'axios';
import host from '../ipaddress'
import { Component } from 'react';
import { type } from 'jquery';

// axios.defaults.baseURL = 'http://159.65.152.211:8002';

const CONNECTION_LOST = 1
const MOVING = 2
const IDLING = 3
const PARKED = 4
const START = 16
const SHUTDOWN = 17
const LOW_SATELLITES = 5
const SLEEPING = 6
const OVERSPEEDING = 7
const DNT = 8
const PARKED_UNKNOWN = 10

const headers =
{
    'Content-Type': 'application/json',
    'user': localStorage.getItem("user_id"),
    'timezone': 'Asia/Kolkata',
    'token': localStorage.getItem("token")
}
const header1 = {
    'user': localStorage.getItem("user_id"),
    // 'user': 134243,
    'token': localStorage.getItem("token"),
    'ostype': 0,
    'versioncode': 11,
    'timezone': 'Asia/Kolkata',
    'Content-Type': 'application/x-www-form-urlencoded'
}

const header2 = {
    //'user': localStorage.getItem("user_id"),
    // 'user': 134243,
    //'token': localStorage.getItem("token"),
    //'ostype': 0,
    //'versioncode': 11,
    'timezone': 'Asia/Kolkata',
    'Content-Type': 'application/x-www-form-urlencoded',
    // 'Access-Control-Allow-Origin' : "payments.matchpointgps.in",
}


class ApiUrlCall {

    createLayout(data) {        
        return axios.post(host + 'v3/track/my_layout/', data, { headers: headers })
    }


    updateLayout(data) {
        return axios.put(host + 'v3/track/my_layout/set/', data, { headers: headers })
    }

    getLayout(data) {

        let head = (() => {
            if (data) {
                return ({
                    'Content-Type': 'application/json',
                    'user': data.user_id,
                    'timezone': 'Asia/Kolkata',
                    'token': data.token
                })
            } else {               
                return headers
            }
        })()

        //console.log('afte cache clear', data)
        return axios.get(host + 'v3/track/my_layout/set/', {
            headers: head
        })
    }

    getOngoingJourney(data) {
        const params = {
            ongoing: true
        }
        let head = (() => {
            if (data) {
                return ({
                    'Content-Type': 'application/json',
                    'user': data.user_id,
                    'timezone': 'Asia/Kolkata',
                    'token': data.token
                })
            } else {
                // console.log('onboarding...........')
                return headers
            }
        })()
        const options = { params, headers: head }
        // console.log('journey data before hitting', options)
        // console.log('journey query parameter', data)
        return axios.get(host + 'v3/track/journeys/', options)
    }
    //this api is used to get journey list data
    getVehicleListdata(data) {
        console.log(data)

        return axios.get(host + "v3/track/journeys/", {
            headers: headers
        })
    }

    // Nornal user Download Report Dashboard
getAllNormalUserState_excel(data) { 
    console.log(data,"Normal user Api Response Data")       
    return axios.post(host + 'v3/track/download_report_for_normal_user/',data, { headers: header1 })
}


    getJourneyList(data){
        return axios.post(host+"v4/track/myjourneys/",data,{headers : header1})
    }

    getVehicleList(data) {
        return axios.post(host +"v3/accounts/my_vehicles/",data, { headers: header1 })
    }

    uniqueJourneyDetail(id) {
        return axios.get(host + 'v3/track/journeys/'+id+'/', { headers: headers })
    }

    getVehicledetails(serial_number) {
        const params = {
            serial_number: serial_number
        }
        return axios.get(host + 'v3/track/vehicles/', { params, headers: headers })
    }
    editVehicleDetails(data) {
        // console.log(">>>>>>><<<<<<<<<<<", data)
        return axios.put(host + 'v3/track/vehicles/', data, { headers: headers })
    }

    createNewJourney(data) {
        // console.log('journey data', data)
        return axios.post(host + 'v3/track/journeys/', data, { headers: headers })
    }

    createJourneyVehicle(journey_id, vehicle_id, data) {
        // console.log('journeyid', journey_id, '>>>>>vehicle id', vehicle_id, '>>>>>>data is', data)
        return axios.post(host + 'v3/track/journeys/' + journey_id + '/vehicles/' + vehicle_id + '/', data, { headers: headers })
    }
   

    driverDetailsData() {
        return axios.get(host + 'v3/track/drivers/', { headers: headers })
    }

    getDriverDetails(data){
        return axios.post(host+'v4/track/mydrivers/',data,{headers : header1})
    }

    updateJourneyDetail(id, data) {
        return axios.put(host + 'v3/track/journeys/' + id + '/', data, { headers: headers })
    }

    addVehicleDocument(data) {
        return axios.post(host + 'v3/track/documents/upload/', data, { headers: headers })
    }

    uniqueidDriverData(id) {
        return axios.get(host + 'v3/track/drivers/' + id, {
            headers: headers,

        })
    }
    createDriver(data) {
        // console.log(data)

        return axios.post(host + 'v3/track/drivers/', data, {
            headers: headers

        })

    }
    uploadDocumentUrl(formData) {

        //console.log(headers)
        return axios.post(host + 'v3/track/documents/upload/', formData, { headers: headers })

    }


    

    updateDriver(data, id) {

        return axios.put(host + 'v3/track/drivers/' + id + '/', data, { headers: headers })

    }

    getDriverJourney(data, id) {
        // console.log('vehicle journey data', data)
        const params = {
            start_time: data.start_time,
            end_time: data.end_time
        }
        return axios.get(host + 'v3/track/drivers/' + id + '/journeys/', { params, headers: headers })
    }
    getVehicleStaticdetails(serial_number) {
        // console.log("???????????????????", serial_number)
        const params = {
            serial_number: serial_number
        }
        return axios.get(host + 'v3/track/vehicles/' + serial_number + '/statistics/', { params, headers: headers })
    }
    getVehicleJourneydetails(data, serial_number) {
        // console.log('vehicle journey data', data)
        const params = {
            start_time: data.start_time,
            end_time: data.end_time
        }
        return axios.get(host + 'v3/track/vehicles/' + serial_number + '/journeys/', { params, headers: headers })
    }

    getAllVehicleLiveTracking() {        
        return axios.post(host + 'v3/track/live_track_all/',{}, { headers: header1 })
    }

    driverDetailsData() {
        return axios.get(host + 'v3/track/drivers/', { headers: headers })
    }
   
    getAllVehicleLiveTrackingDGreports(data) { 
        
        //console.log(data,"api")       
        //return axios.post(host + 'v3/track/current_location_of_mdg_new/',data, { headers: header1 })
        return axios.post(host + 'v3/track/current_location_of_mdg_new/',data, { headers: header1 , timeout: 10000 })
  
    }
    
    // getAllVehicleState(data) { 
    //     //console.log(data,"api")       
    //     return axios.post(host + 'v3/track/live_track_account/',data, { headers: header1 })
    // }
    

    //Commenteddd

    getAllVehicleLiveTrackingNew(data) { 
        //console.log(data,"api")       
        return axios.post(host + 'v3/track/live_track_all_new/',data, { headers: header1 })
    }

    checkDashCamera(data) { 
        //console.log(data,"api")       
        return axios.post(host + 'v3/track/check_dashcam_serial_number/',data, { headers: header1 })
    }

    fetchLiveStreamingcamera(data) { 
        //console.log(data,"api")       
        return axios.post(host + 'v3/track/fetch_live_streaming_urls/',data, { headers: header1 })
    }

    getAllVehicleState(data) { 
        //console.log(data,"api")       
        return axios.post(host + 'v3/track/live_track_account/',data, { headers: header1 })
    }


/// INDUS
getAllVehicleLiveTrackingNewIndus(data) { 
    //console.log(data,"api")       
    return axios.post(host + 'v3/track/live_track_all_new_indus/',data, { headers: header1 })
}

getAllVehicleStateIndus(data) { 
    //console.log(data,"api")       
    return axios.post(host + 'v3/track/live_track_all_new_indus/',data, { headers: header1 })
   // return axios.post(host + 'v3/track/live_track_account_count_indus/',data, { headers: header1 })
}

///normal user
getAllVehicleLiveTrackingNewNormal(data) { 
    //console.log(data,"api")       
    return axios.post(host + 'v3/track/live_track_all_new_normal/',data, { headers: header1 })
}
getAllVehicleStateNormal(data) { 
    //console.log(data,"api")       
    return axios.post(host + 'v3/track/live_track_account_normal/',data, { headers: header1 })
}


    getAllDigiState_excel(data) { 
        //console.log(data,"api")       
        return axios.post(host + 'v3/track/download_report_for_dg/',data, { headers: header1 })
    }
    
    vehicle_getAllDigiState_excel(data) { 
        //console.log(data,"api")       
        return axios.post(host + 'v3/track/download_report_for_vehicle_live/',data, { headers: header1 })
    }


    getAllVehicleStateDG(data) { 
        //console.log(data,"api")       
        return axios.post(host + 'v3/track/current_location_of_mdg_count/',data, { headers: header1 })
    }
    

    getSingleVehicleLiveTrack(data) {
        return axios.post(host + 'v3/track/live_summary/', data, { headers: header1 })
    }

    getReplayRoute(data) {
        return axios.post(host + 'v3/track/replay_route/', data, { headers: header1 })
    }

    getAddressMMI(data) {
        // console.log('data before sending', data)
        return axios.post(host + 'v3/track/get_geo_address/', data, { headers: header1 })
    }

    widgetDriverScore() {
        return axios.post(host + 'v3/track/stats/driver_score/', {}, { headers: header1 })
    }

    widgetDriverAvailabilty() {
        return axios.post(host + 'v3/track/stats/driver_availability/',{}, { headers: header1 })
    }

    widgetVehicleExpense() {
        return axios.post(host + 'v3/track/stats/vehicle_expenses/',{}, { headers: header1 })
    }

    widgetVehicleDocument() {
        return axios.post(host + 'v3/track/stats/vehicle_web_documents_summary/',{}, { headers: header1 })
    }

    widgetVehicleUsage() {
        return axios.post(host + 'v3/track/stats/vehicle_usage/',{}, { headers: header1 })
    }

    widgetTodaysJourney() {
        return axios.post(host + 'v3/track/stats/journey_summary/',{}, { headers: header1 })
    }

    widgetFleetSummary() {
        return axios.post(host + 'v3/track/stats/fleet_summary/',{}, { headers: header1 })
    }

    widgetVehicleStat() {
        return axios.post(host + 'v3/track/stats/vehicle_status_count_widget/',{}, { headers: header1 })
    }


    widgetVehicleStat1() {
        return axios.post(host + 'v3/track/stats/overspeeding_nightdrive_count_widget/',{}, { headers: header1 })
    }


    getModeConfigData(data) {
        return axios.post(host + 'v3/modes/mode_config/', data, { headers: header1 })
    }

    getBoundaryAlertList(data) {
        return axios.post(host + 'v3/modes/boundaries/list/', data, { headers: header1 })
    }

    createBoundaryAlert(data) {
        return axios.post(host + 'v3/modes/boundaries/add/', data, { headers: header1 })
    }

    createRegionBoundaryAlert(data) {
        return axios.post(host + 'v3/modes/boundaries/add_region_boundary/', data, { headers: header1 })
    }

    createSpeedAlert(data) {
        return axios.post(host + 'v3/modes/update_speed_alert/', data, { headers: header1 })
    }

    updateRegionSpeedAlert(data) {
        return axios.post(host + 'v3/modes/update_region_speed/', data, { headers: header1 })
    }
    
    updateRegionStoppageAlert(data) {
        return axios.post(host + 'v3/modes/update_region_stoppage/', data, { headers: header1 })
    }

    createZone(data) {
        return axios.post(host + 'v3/track/regions/', data, { headers: headers })
    }
    updateZone(id, data) {
        return axios.put(host + 'v3/track/regions/' + id + '/', data, { headers: headers })
    }
    getZone() {
        return axios.get(host + 'v3/track/regions/', { headers: headers })
    }
    deleteZone(id){
        return axios.delete(host+'v3/track/regions/'+id+'/',{headers : headers})
    }
    getUserProfile() {
        return axios.post(host + 'v3/accounts/my_account/basics/',{}, { headers: header1 })
    }
    getUserSubscription() {
        return axios.post(host + 'v3/accounts/my_account/subscription/',{}, { headers: header1 })
    }
    UpdateUserProfile(data) {
        return axios.post(host + 'v3/accounts/my_account/profile/update/', data, { headers: header1 })
    }
    getAlertSetting() {
        return axios.post(host + 'v3/reports/periodic_report_status/',{}, { headers: header1 })
    }
    getFleetSummarySetting() {
        return axios.post(host + 'v3/track/settings/fleet_summary/',{}, { headers: headers })
    }
    getNotification(data) {
        return axios.post(host + 'v3/alerts/user_notifications/', data, { headers: header1 })
    }
    getLicenseType() {
        return axios.post(host + 'v3/driver/get_license_types/',{}, { headers: header1 })
    }
    journey_Report(data){
        
        return axios.post(host+'v3/track/reports/journeys/',data,{headers:headers})
    }
    journey_Report_WithStops(data){
        
        return axios.post(host+'v3/track/reports/journeyswithstops/',data,{headers:headers})
    }
    generate_vehicle_report_graph(data){
        
        return axios.post(host+'v3/track/reports/vehicles/',data,{headers:headers})

    }
    send_analytics(data){
        
        return axios.post(host+'v3/track/collect_analytics/',data,{headers:headers})

    }
    generate_trips_report_graph(data){
        
        return axios.post(host+'v3/reports/trips/',data,{headers:headers})

    }
    generate_trips_report_graph_mp(data){
        
        return axios.post(host+'v3/reports/trips_indus/',data,{headers:headers})

    }

      //summary report mp indus
      summary_report_mp_indus(data){
        
        return axios.post(host+'v3/reports/summary_report_mp_indus/',data,{headers:headers})

    }
    
    generate_travel_report(data){
        
        return axios.post(host+'v3/reports/travel_detail/',data,{headers:headers})

    }

    generate_location_report(data){
        
        return axios.post(host+'v3/reports/raw_location/',data,{headers:headers})

    }
    generate_stoppage_report(data){
        
        return axios.post(host+'v3/reports/stoppage/',data,{headers:headers})

    }
    

    generate_stoppage_report_mp_indus(data){
        
        return axios.post(host+'v3/reports/stoppage_indus/',data,{headers:headers})

    }

    generate_dgreport_report(data){
        
        return axios.post(host+'v3/reports/dgreport/',data,{headers:headers})

    }

    generate_dgreport_report_og(data){
        
        return axios.post(host+'v3/reports/dgreport_og/',data,{headers:headers})

    }
     

    getVehicleInfoData(data) { 
        // console.log(data,"api popup")       
         return axios.post(host + 'v3/track/get_live_track_info/',data, { headers: header1 })
     }
     
     cluster_Name() {
        var data = {}
       
      // return axios.post(host + 'v3/track/aom_cluster/ ', {}, { headers: headers })
        
        return axios.post(host + 'v3/track/circle_cluster/ ', {}, { headers: headers })
    }
   
    delete_your_account() {
        var data = {}
    
     
        return axios.post(host + 'v3/accounts/get_mobile_number/ ', {}, { headers: headers })
    }

    account_delete() {
        var data = {}
    
     
        return axios.post(host + 'v3/accounts/delete_account/ ', {}, { headers: headers })
    }




    cluster_Name_new() {
        var data = {}
       
      // return axios.post(host + 'v3/track/aom_cluster/ ', {}, { headers: headers })
        
        return axios.post(host + 'v3/reports/filter_mapping/ ', {}, { headers: headers })
    }





    // master_dashbord_api() {
    //     var data = {}    
    //     return axios.post(host + 'v3/reports/master_dashboard/ ', {}, { headers: headers })
    // }
    

    master_dashbord_api(data){
        
        return axios.post(host+'v3/reports/master_dashboard/',data,{headers:headers})

    }


    imd_dashbord_api(data){
        
      //  return axios.post(host+'v3/reports/mumbai_circle_report/',data,{headers:headers})
     return axios.post(host+'v3/reports/master_dashboard_km/',data,{headers:headers})

    }



    mumbai_dashbord_api(data){
        
        return axios.post(host+'v3/reports/mumbai_circle_report/',data,{headers:headers})
       // return axios.post(host+'v3/reports/master_dashboard_km/',data,{headers:headers})

    }


    generate_inactive_report(data){
        
        return axios.post(host+'v3/reports/inactive_summary/',data,{headers:headers})

    }

    generate_idling_detail_report(data){
        return axios.post(host+'v3/reports/idling_detail/',data,{headers:headers})
    }

    generate_idling_detail_report_mp(data){
        return axios.post(host+'v3/reports/idling_indus_detail/',data,{headers:headers})
    }

    generate_current_location_detail(data){
        return axios.post(host+'v3/reports/current_location_of_mdg/',data,{headers:headers})
    }
    
    generate_vehicle_movement_24Hrs(data){
        return axios.post(host+'v3/reports/vehicle_movement_24_hrs/',data,{headers:headers})
    }

    generate_vehicle_idle_report(data){
        return axios.post(host+'v3/reports/mdg_idle_report/',data,{headers:headers})
    }

    generate_speed_report_gt_50(data){
        return axios.post(host+'v3/reports/speed_report_gt_50/',data,{headers:headers})
    }

    generate_device_health_report(data){
        return axios.post(host+'v3/reports/device_health_report/',data,{headers:headers})
    }

    generate_vehicle_idle_24Hrs(data){
        return axios.post(host+'v3/reports/vehicle_idle_gt_24_hrs/',data,{headers:headers})
    }

    generate_Summary_report(data){
        
    return axios.post(host + 'v3/track/reports/summary/',data,{headers:headers})
    }
    get_Region_List(){
        return axios.get(host + 'v3/track/regions/',{headers:headers})
    }
    generate_driver_report_graph(data){
       
        return axios.post(host+'v3/track/reports/drivers/',data,{headers:headers})

    }
//remove this api and use the old one	
    getVehcile_Name() {
        //var data = {}
        return axios.post(host + 'v3/track/vehicle_list/ ', {}, { headers: headers })
    }



    getVehcile_Name_latest(data) {
        return axios.post(host + 'v3/track/vehicle_list_report/ ', data, { headers: headers })
    }

    //MMI auto suggestion API 
    getAddressIQ(address){
        const address_query = {"query":address}
        const data = JSON.stringify(address_query)
        const address_data = axios.post(host + 'v3/track/get_mmi_address/',data,{headers:headers})
        return address_data
        // const params = {
        //     key : "pk.13026e708daf73a9e7cf30547615efdb",
        //     q : address
        // }
        // //const location_params = get_location_params(address)
        // return axios.get('https://api.locationiq.com/v1/autocomplete.php',{params})
        
       
    }

    get_location_params(address){
        const params = {
            key : "pk.13026e708daf73a9e7cf30547615efdb",
            q : address
        }
        return params

    }

    
    getMmiTokenForAddress(){
        
    }

    //location IQ auto search address
    // getAddressIQ(address){
    //     console.log(address , " GOTTE ADDRESS FROM INPUT " )
    //     const params = {
    //         key : "pk.13026e708daf73a9e7cf30547615efdb",
    //         q : address
    //     }
    //     console.log(axios.get('https://api.locationiq.com/v1/autocomplete.php',{params}) , " LOCATION IQ RESP GOTTE ")
    //     return axios.get('https://api.locationiq.com/v1/autocomplete.php',{params})
    // }

    startJourneyVehicle(data){
        return axios.post(host+'v3/driver/operate_vehicle_journey/',data,{headers : header1})
    }

    journeyVehicleNotification(data){        
        return axios.post(host+'v3/alerts/notifications_by_time/',data,{headers : header1})
    }

    toggleAlert(data){

        return axios.post(host+'v3/modes/toggle_alert/',data,{headers : header1})
    }  
    savePeriodicReport(data){
        return axios.post(host+'v3/reports/update_periodic_report_status/',data,{headers : header1})
    }  
    getJourneyDocument(data){
        return axios.post(host+'v3/track/journey_docs/',data,{headers : header1})
    }
    documentExpenseType(){
        return axios.post(host+'v3/driver/get_expense_types/',{headers : header1})
    }
    deleteJourney(id){
        return axios.delete(host+'v3/track/journeys/'+id+'/',{headers : headers})
    }
    getDetailDocuments(data){       
        return axios.post(host+'v3/track/documents/',data,{headers : header1})
    }
    getAlertGraphData(data){
        return axios.post(host+'v3/track/reports/alert_categories/',data,{headers : header1})
    }
    updateBoundary(data){
        return axios.post(host+'v3/modes/boundaries/update/',data,{headers : header1})
    }
    deleteBoundaryAlert(data){
        return axios.post(host+'v3/modes/boundaries/remove/',data,{headers : header1})
    }
    updateEndpointServer(data){
        const header = {
            'user': localStorage.getItem("user_id"),
            'token': localStorage.getItem("token"),
            'ostype': 2,
            'versioncode': 11,
            'timezone': 'Asia/Kolkata',
            'Content-Type': 'application/x-www-form-urlencoded',
            'modelname': 'webapp',
        }
        return axios.post(host+'v3/accounts/update_aws_endpoint/', data, {headers : header})
    }
    getIpAddress(){
        return axios.get('https://api.ipify.org?format=json')
    }
    customizedSummaryReport(data){
        return axios.post(host+'v3/reports/customised_report/summary/',data,{headers : header1})
    }
    customizedOverSpeedReport(data){
        return axios.post(host+'v3/reports/customised_report/over_speed/', data,{headers : header1})
    }
    customizedDetailReport(data){
        return axios.post(host+'v3/reports/customised_report/detailed/', data, {headers : header1})
    }
    customizedNightModeReport(data){
        return axios.post(host+'v3/reports/customised_report/night_mode/',data,{headers: header1})
    }
    getCategoryData(){
        return axios.post(host+'v3/reports/customised_report/get_category/',{},{headers : header1})
    }
    singleTripDetail(data){
        return axios.post(host+'v3/reports/customised_report/trip_selected/',data,{headers : header1})
    }
    singleNightTrip(data){
        return axios.post(host+'v3/reports/customised_report/night_trip_selected/',data,{headers : header1})
    }
    singleNightTripDetailed(data){
        return axios.post(host+'v3/reports/customised_report/night_trip_detail_data/',data,{headers : header1})
    }
    getApiIntegrationState(){
        return axios.post(host+'v3/accounts/pub_api/status/',{},{headers : header1})
    }
    setApiIntegrationState(data){
        return axios.post(host+'v3/accounts/pub_api/toggle/',data,{headers : header1})
    }
    regenerateApiKey(){
        return axios.post(host+'v3/accounts/pub_api/regenerate/',{},{headers : header1})
    }
    apiVehicleList(){
        return axios.post(host+'v3/accounts/pub_api/vehicle_list/',{},{headers : header1})
    }
    getApiList(){
        return axios.post(host+'v3/accounts/pub_api/availale_api_list/',{},{headers : header1})
    }
    generateApiReport(data){
        return axios.post(host+'v3/reports/pub_api/usage_report/',data,{headers : header1})
    }
    getApiDocument(){
        return axios.post(host+'v3/accounts/pub_api/doc/',{},{headers : header1})
    }
    sentEmailViaReport(data){
        return axios.post(host+'v3/reports/pub_api/email_report/',data,{headers : header1})
    }
    openTokSession(){
        return axios.post(host+'v3/accounts/create_screen_sharing_session/',{},{headers : header1})
    }
    openTokAck(data){
        return axios.post(host+'v3/accounts/screen_sharing_ack/',data,{headers : header1})
    }
    sendRating(data){
        return axios.post(host+'v3/accounts/store_screen_sharing_feedback/',data,{headers : header1})
    }
    updateJourney(data){
        return axios.post(host+'v4/track/myjourneys/updatedriver/',data,{headers : header1})
    }
    getFuelDetail(data){
        return axios.post(host+'v4/track/vehicles/fuel_details/', data, {headers : header1})
    }
    getFuelReport(data){
        return axios.post(host+'v3/reports/fuel_report/',data,{headers : header1})
    }
    
    //Plug and play API
    getTrackerDetails(data){
        return axios.post(host+'v3/rental_tracking/tracker_status/',data,{headers : header1})
    }
    getRentalReport(data){
        return axios.post(host+'v3/rental_tracking/tracker_report/',data,{headers : header1})
    }
    getFullArea(){
        return axios.post(host+'v3/rental_tracking/load_rental_map_area/',{},{headers : header1})
    }
    getLoadingPointsLocation(){
        return axios.post(host+'v3/rental_tracking/load_boundary_location/',{},{headers : header1})
    }
    getLoadingPointsData(){
        return axios.post(host+'v3/rental_tracking/boundary_location_count/',{},{headers : header1})
    }
    getNotificationCount(){
        return axios.post(host+'v3/rental_tracking/dashboard_notification_count/',{},{headers : header1})
    }
    searchVehicleNumber(data){        
        return axios.post(host+'v3/rental_tracking/dashboard_search/',data,{headers : header1})
    }
    jswNotificationList(data){
        return axios.post(host+'v3/rental_tracking/frd_notification/',data,{headers : header1})
    }
    jswActiveLiveTrack(){
        return axios.post(host+'v3/rental_tracking/active_live_track/',{},{headers : header1})
    }
    fuelStatsDetails(data){
        return axios.post(host+'v3/track/fuel_stats/',data,{headers : header1})
    }
    fuelStatsReport(data){
        return axios.post(host+'v3/track/fuel_usage_summary/',data,{headers : header1})
    }
    getWidgetFuelDetails(){
        return axios.post(host+'v3/track/stats/fuel_expenses_widget/',{},{headers : header1})
    }
    getWidgetFuelConsumed(){
        return axios.post(host+'v3/track/stats/fuel_mip_stats/',{},{headers : header1})
    }
    createPostJourney(data){
        return axios.post(host+'v3/track/journeys/create_post_journey/',data,{headers : header1})
    }
    updateEmail(data){
        return axios.post(host+'v3/accounts/update_email/',data,{headers : header1})
    }
    // invite user api
    createInvite(data){
        return axios.post(host+'v3/accounts/invite_user/',data,{headers : header1})
    }
    inviteUserList(data){
        return axios.post(host+'v3/accounts/list_invited_users/',data,{headers : header1})
    }
    inviteUserDetails(data){
        return axios.post(host+'v3/accounts/user_details/',data,{headers, header1})
    }
    inviteUserUpdateDetails(data){
        return axios.post(host+'v3/accounts/update_user_details/',data,{headers, header1})
    }
    deleteInviteUser(data){
        return axios.post(host+'v3/accounts/remove_invited_users/',data,{headers : header1})
    }
    //Vendor api
    getVendorList(){
        return axios.post(host+'v3/accounts/api_vendor_list/',{},{headers : header1})
    }
    addTrackerToVendor(data){
        return axios.post(host+'v3/accounts/add_vendor_tracker/',data,{headers : header1})
    }
    getVendorMapping(){
        return axios.post(host+'v3/accounts/nicer_mapping_list/',{},{headers : header1})
    }
    updateRegistrationNumber(data){
        return axios.post(host+'v3/track/update_reg_number/',data,{headers : header1})
    }
    //share live track replay route
    shareTracking(data){
        return axios.post(host+'v3/track/share_tracking/',data,{headers : header1})
    }  
    //nice globe api 
    trackerManagementList(data){
        return axios.post(host+'v3/accounts/tracker_mgmt/',data,{headers : header1})
    }
    addTempVehicle(data){
        return axios.post(host+'v3/accounts/add_tmp_vehicle/',data,{headers : header1})
    }
    removeTrackerFromApi(data){
        return axios.post(host+'v3/accounts/remove_vendor_tracker/',data,{headers : header1})
    }

    // tracker management api
    addTemporaryVehicle(data){
        return axios.post(host+'v3/accounts/add_tmp_vehicle/',data,{headers : header1})
    }
    untrackVehicleList(){
        return axios.post(host+'v3/track/unmapped_vehicle_list/',{},{headers : header1})
    }
    unmapTracker(data){
        return axios.post(host+'v3/track/unmap_tracker/',data,{headers : header1})
    }
    mapTrackerToVehicle(data){
        return axios.post(host+'v3/track/map_tracker/',data,{headers : header1})
    }
    trackerMgmtReport(data){
        return axios.post(host+'v3/accounts/tracker_mapping_report/',data,{headers : header1})
    }
    //people tracking API
    ptAttendanceDashboard(){
        return axios.post(host+'v3/people_tracking/attendance_summary/',{},{headers : header1})
    }
    ptVisitDashboard(){
        return axios.post(host+'v3/people_tracking/planned_visits_summary/',{},{headers : header1})
    }
    ptUserStatusDashboard(){
        return axios.post(host+'v3/people_tracking/user_status_summary/',{},{headers : header1})
    }
    ptVoluntaryDashboard(){
        return axios.post(host+'v3/people_tracking/voluntary_visits_summary/',{},{headers : header1})
    }
    ptAdminList(data){
        return axios.post(host+'v3/people_tracking/users/list/',data,{headers : header1})
    }
    ptVisitList(data){
        return axios.post(host+'v3/people_tracking/visits/list/',data,{headers : header1})
    }
    ptAddUser(data){
        return axios.post(host+'v3/people_tracking/users/add/',data,{headers : header1})
    }
    ptDeleteUser(data){
        return axios.post(host+'v3/people_tracking/users/remove/',data,{headers : header1})
    }
    ptUserDetails(data){
        return axios.post(host+'v3/people_tracking/users/details/',data,{headers : header1})
    }
    ptUpdateUserDetails(data){
        return axios.post(host+'v3/people_tracking/users/update/',data,{headers : header1})
    }
    ptAddVisit(data){
        return axios.post(host+'v3/people_tracking/visits/add/',data,{headers : header1})
    }
    ptVisitDetails(data){
        return axios.post(host+'v3/people_tracking/visits/details/',data,{headers : header1})
    }
    ptLiveTrack(){
        return axios.post(host+'v3/people_tracking/users_tracking_list/',{},{headers : header1})
    }
    ptAlerts(data){
        return axios.post(host+'v3/people_tracking/alerts/list/',data,{headers : header1})
    }
    ptAttendanceReport(data){
        return axios.post(host+'v3/people_tracking/users/attendance_report/',data,{headers : header1})
    }
    ptVisitReport(data){
        return axios.post(host+'v3/people_tracking/users/visit_report/',data,{headers : header1})
    }
    ptActivityReport(data){
        return axios.post(host+'v3/people_tracking/users/activity_report/',data,{headers : header1})
    }
    ptSubscriptionList(){
        return axios.post(host+'v3/people_tracking/sub/list/',{},{headers : header1})
    }
    ptSubscriptionPrice(data){
        return axios.post(host+'v3/people_tracking/sub/get_sku_price/',data,{headers : header1})
    }
    ptPaymentRedirect(){
        return axios.post(host+'v3/people_tracking/payments/redirect_payment/',{},{headers : header1})
    }
    ptTransactionID(){
        return axios.post(host+'v3/people_tracking/payments/get_transaction_id/',{},{headers : header1})
    }
    ptDefaultView(data){
        return axios.post(host+'v3/people_tracking/update_default_view/',data,{headers : header1})
    }
    ptUpdateVisit(data){
        return axios.post(host+'v3/people_tracking/visits/update/',data,{headers : header1})
    }

    JourneyGetStops(data){
        return axios.post(host+'v3/track/journey_stops/',data,{headers : header1})
    }

    getVehicleIcon(vehicleType, vehicleState,trackerState){
        //console.log("Vehicle Type: " + vehicleType)
        //console.log("Vehicle State " + vehicleState)
        //console.log("Vehicle Name " + vehicleName)

        const CAR_ICONS = ["images/vehicle_icon/Car moving.png", "images/vehicle_icon/Car idling.png", "images/vehicle_icon/Car parked.png", "images/vehicle_icon/Car lost connection.png"];
        
        const BIKE_ICONS = ["images/vehicle_icon/Bike moving.png", "images/vehicle_icon/Bike idling.png", "images/vehicle_icon/Bike parked.png", "images/vehicle_icon/Bike lost connection.png"];
        const BUS_ICONS = ["images/vehicle_icon/Bus moving.png", "images/vehicle_icon/Bus idling.png", "images/vehicle_icon/Bus parked.png", "images/vehicle_icon/Bus lost connection.png"];
        const GARBAGE_TRUCK_ICONS = ["images/vehicle_icon/Garbage Truck moving.png", "images/vehicle_icon/Garbage Truck idling.png", "images/vehicle_icon/Garbage Truck parked.png", "images/vehicle_icon/Garbage Truck lost connection.png"];
        const AMBULANCE_ICONS = ["images/vehicle_icon/Ambulance moving.png", "images/vehicle_icon/Ambulance idling.png", "images/vehicle_icon/Ambulance parked.png", "images/vehicle_icon/Ambulance lost connection.png"];
        const FIRE_TRUCK_ICONS = ["images/vehicle_icon/Fire Truck moving.png", "images/vehicle_icon/Fire Truck idling.png", "images/vehicle_icon/Fire Truck parked.png", "images/vehicle_icon/Fire Truck lost connection.png"];
        const TAXI_ICONS = ["images/vehicle_icon/Taxi moving.png", "images/vehicle_icon/Taxi idling.png", "images/vehicle_icon/Taxi parked.png", "images/vehicle_icon/Taxi lost connection.png"];
        const VIP_CAR_ICONS = ["images/vehicle_icon/VIP Car moving.png", "images/vehicle_icon/VIP Car idling.png", "images/vehicle_icon/VIP Car parked.png", "images/vehicle_icon/VIP Car lost connection.png"];
        const BLOOD_BANK_ICONS = ["images/vehicle_icon/Blood Bank moving.png", "images/vehicle_icon/Blood Bank idling.png", "images/vehicle_icon/Blood Bank parked.png", "images/vehicle_icon/Blood Bank lost connection.png"];
        const TRUCK_ICONS = ["images/vehicle_icon/Truck moving.png", "images/vehicle_icon/Truck idling.png", "images/vehicle_icon/Truck parked.png", "images/vehicle_icon/Truck lost connection.png"];
        const SERVICE_ENG_ICONS = ["images/vehicle_icon/SE moving.png", "images/vehicle_icon/SE idling.png", "images/vehicle_icon/SE parked.png", "images/vehicle_icon/SE lost connection.png"];
        const DG_ICONS = ["images/vehicle_icon/DG ON.png", "images/vehicle_icon/DG OFF.png", "images/vehicle_icon/DG OFF.png" , "images/vehicle_icon/DG LOST connection.png"];
        const DASH_CAM = ["images/vehicle_icon/Dashcam_Moving.png", "images/vehicle_icon/dashcam_iconidle.png", "images/vehicle_icon/Dashcam_Parked.png" , "images/vehicle_icon/DashCam_connectionlost.png"];

        const ICONS = { 1: CAR_ICONS, 2: BIKE_ICONS, 3: BUS_ICONS, 4: GARBAGE_TRUCK_ICONS, 
                        5: AMBULANCE_ICONS, 6: FIRE_TRUCK_ICONS, 7: TAXI_ICONS, 8: VIP_CAR_ICONS, 
                        9: BLOOD_BANK_ICONS, 11: TRUCK_ICONS,14: SERVICE_ENG_ICONS ,15:DG_ICONS ,16:DASH_CAM}

        //console.log("Icons array ****" + ICONS[1])
        //console.log(ICONS[vehicleType])

        // if(vehicleState === MOVING || vehicleState === OVERSPEEDING) return ICONS[vehicleType][0]
        // else if(vehicleState === IDLING) return ICONS[vehicleType][1]
        // else if(vehicleState === PARKED || vehicleState === PARKED_UNKNOWN) return ICONS[vehicleType][2]
        // else return ICONS[vehicleType][3]

        if(trackerState === 1){
            return ICONS[vehicleType][3]
            
        }else{
            if(vehicleState === MOVING || vehicleState === OVERSPEEDING ) return ICONS[vehicleType][0]
            else if(vehicleState === IDLING) return ICONS[vehicleType][1]
            else if(vehicleState === PARKED || vehicleState === PARKED_UNKNOWN) return ICONS[vehicleType][2]
            else if(vehicleState === START) return ICONS[vehicleType][0]
            else if(vehicleState === SHUTDOWN) return ICONS[vehicleType][1]
            else return ICONS[vehicleType][3]
        } 
    }

    ShowPudoUserList(data){
        return axios.post(host+'v3/track/myvender/',data,{headers : header1})
    }

    accountSubscriptionNew(data){
        return axios.post(host+'v3/accounts/my_account/account_subscription_new/',data,{headers : header1})
    }

    getlocationdata(){
        return axios.post(host + 'v3/track/interesting_locations/',{}, { headers: header1 })
    }

    deleteLocation(data) {
        return axios.post(host+'v3/track/deleting_locations/',data,{headers : header1})
    }

    addLocation(data){
        return axios.post(host+'v3/track/adding_locations/',data,{headers : header1})
    }

    ShowVehicleGroupList(data){
        return axios.post(host+'v3/accounts/list_vehicle_group/',data,{headers : header1})
    }

    AddVehicleGroup(data){
        return axios.post(host+'v3/accounts/vehicle_group_detail/',data,{headers : header1})
    }

    deleteGroup(data){
        return axios.post(host+'v3/accounts/delete_vehicle_group/',data,{headers : headers})
    }

    deleteVehicle(data){
        return axios.post(host+'v3/accounts/delete_single_vehicle_from_group/',data,{headers : header1})
    }

    UpdateVehicleGroup(data){
        return axios.put(host+'v3/accounts/vehicle_group_detail/',data,{headers : header1})
    }

    getReportType() {        
        return axios.post(host + 'v3/reports/get_report_types/',{}, { headers: header1 })
    }

    get_period_types(data) {        
        return axios.post(host + 'v3/reports/get_period_types/',data, { headers: header1 })
    }

    get_circle_types(data) {        
        return axios.post(host + 'v3/reports/get_circle_types/',data, { headers: header1 })
    }
    
    get_area_types(data) {        
        return axios.post(host + 'v3/reports/get_area_types/',data, { headers: header1 })
    }

    ShowVehicleGroupList(data){
        return axios.post(host+'v3/accounts/list_vehicle_group/',data,{headers : header1})
    }

    getEmailAddress(){
        return axios.post(host+'v3/reports/get_email_address/',{},{headers : header1})
    }

    getRecentReport(data){
        return axios.post(host+'v3/reports/recent_report/',data,{headers : header1})
    }

    createSchedularReport(data){
        return axios.post(host+'v3/reports/scheduler_report/',data,{headers : header1}) 
    }

    sendDataByDownloadLink(data){
        return axios.post(host+'v3/reports/generate_link/',data,{headers : header1})
    }

    sendBalloonPinData(data){
        return axios.post(host+'v3/track/balloon_pin_option/',data,{headers : header1})
    }

    getLocationIcon(){
        const ICONS = ["images/location_sym.png",]
        return ICONS[0]
    }

    checkUserHasLocationData(){
        return axios.post(host+'v3/track/user_has_location_data/',{},{headers : header1})
    }

    addUser(data){
        return axios.post(host+'v3/rental_tracking/user_mgmt/',data,{headers : header1})
    }

    showUserList(){
        return axios.post(host+'v3/rental_tracking/user_list/',{},{headers : header1})
    }

    deleteUserData(data){
        return axios.post(host+'v3/rental_tracking/user_delete/',data,{headers : header1})
    }

    showCurrentPlanDetails(data){
        return axios.post(host+'v3/accounts/tracker_sub_list/new/',data,{headers : header1})
    }

    showRenewalPlan(data){
        return axios.post(host+'v3/accounts/tracker_renewal_plan/',data,{headers : header1})
    }

    payNowClick(data){
        return axios.post(host+'v3/accounts/tracker_prepayment_data/',data,{headers : header1})
    }

    paymentData(data){
        return axios.post('https://payments.matchpointgps.in/ccavenue/ccavRequestHandler/',data,) 
    }

    getAllVehicleList(data) {
        return axios.post(host +"v3/accounts/get_all_vehicle_list/",data, { headers: header1 })
    }
    createJourneyUploadingExcel(data) {
        return axios.post(host + 'v3/track/create_journey_uploading_excel/', data, { headers: headers })
    }
   
    generate_fuel_report_graph(data){
        
        //return axios.post(host+'v3/track/reports/fuelreport2/',data,{headers:headers})
        return axios.post(host+'v3/track/reports/fuel_values/',data,{headers:headers})
         
       }
   
       generate_refuel_report_graph(data){
          
          
           //return axios.post(host+'v3/track/reports/fuel_refuel_test/',data,{headers:headers})
           return axios.post(host+'v3/track/reports/fuel_refuel/',data,{headers:headers})
   
       }
       generate_fuel_theft_report_graph(data){
          
           
           //return axios.post(host+'v3/track/reports/fuel_theft/',data,{headers:headers})
           return axios.post(host+'v3/track/reports/fuel_theft/',data,{headers:headers})
   
   
       }
       generate_economy_report_graph(data){
       
          // return axios.post(host+'v3/track/reports/fuel_economy/',data,{headers:headers})
           return axios.post(host+'v3/track/reports/fuel_economy/',data,{headers:headers})
   
       }
   


    
}

export default new ApiUrlCall();
